import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

import { computed, type PropType, ref } from 'vue';
import { MenuItem } from '@/shared/components/menus/models/menu-item';
import { type IconName, IconType } from '@shared/components/icon/icon.type';
import VzPopover from '@shared/components/menus/vz-popover.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-popover-menu',
  props: {
  dim: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  items: { type: Array as PropType<Array<MenuItem>>, default: () => [] },
  size: { type: [String, Number], default: 42 },
  offsetX: { type: [String, Number], default: 4 },
  offsetY: { type: [String, Number], default: 0 },
  uppercase: { type: Boolean, default: true },
  iconType: { type: String as PropType<IconType>, default: 'solid' },
  showActionForOneItem: { type: Boolean, default: false },
  iconName: { type: String as PropType<IconName>, default: 'svg:menu' },
},
  setup(__props) {

const props = __props;

const loadingIndex = ref<number | null>(null);

const itemList = computed(() => (props.items || []).filter((item) => !item.hidden));
const hasOneItem = computed(() => itemList.value.length === 1);

const firstItem = computed(() => itemList.value[0]);

const onSelect = async (callback: () => unknown | Promise<unknown>, index: number, closeCallback?: () => void): Promise<void> => {
  try {
    loadingIndex.value = index;
    await callback();
    closeCallback?.();
  } finally {
    loadingIndex.value = null;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_vz_divider = _resolveComponent("vz-divider")!
  const _component_vz_button = _resolveComponent("vz-button")!

  return (!__props.showActionForOneItem || !hasOneItem.value)
    ? (_openBlock(), _createBlock(VzPopover, {
        key: 0,
        dim: "",
        "icon-name": __props.iconName
      }, _createSlots({
        default: _withCtx(({ close }) => [
          _renderSlot(_ctx.$slots, "top"),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemList.value, (item, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              (item.divider === 'before')
                ? (_openBlock(), _createBlock(_component_vz_divider, {
                    key: 0,
                    class: "mt-1"
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_vz_button, {
                class: "text-title-1 my-2",
                type: "flat",
                "icon-color": "primary-900",
                uppercase: __props.uppercase,
                loading: loadingIndex.value === index,
                "icon-name": item.icon?.name,
                "icon-type": item.icon?.type || __props.iconType,
                "icon-size": item.icon?.size || '1.5rem',
                text: item.text,
                onClick: ($event: any) => (onSelect(item.click, index, close))
              }, null, 8, ["uppercase", "loading", "icon-name", "icon-type", "icon-size", "text", "onClick"]),
              (item.divider === 'after')
                ? (_openBlock(), _createBlock(_component_vz_divider, { key: 1 }))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          _renderSlot(_ctx.$slots, "bottom")
        ]),
        _: 2
      }, [
        (_ctx.$slots['activator'])
          ? {
              name: "activator",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "activator")
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["icon-name"]))
    : (_openBlock(), _createBlock(_component_vz_button, {
        key: 1,
        type: "flat",
        "icon-color": "primary-900",
        uppercase: __props.uppercase,
        loading: loadingIndex.value === 0,
        "icon-name": firstItem.value.icon?.name,
        "icon-type": firstItem.value.icon?.type || __props.iconType,
        "aria-label": firstItem.value.text,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (onSelect(firstItem.value.click, 0)))
      }, null, 8, ["uppercase", "loading", "icon-name", "icon-type", "aria-label"]))
}
}

})