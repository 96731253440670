import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_3 = {
  key: 1,
  class: "canvas-board-cropper__preview"
}
const _hoisted_4 = ["src", "height", "width", "alt"]
const _hoisted_5 = {
  key: 0,
  class: "canvas-board-cropper__cropper-add-button"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 3,
  class: "canvas-board-cropper__control"
}

import { computed, type PropType, ref, watch } from 'vue';
import FileService from '@shared/services/file.service';
import VzIcon from '@shared/components/icon/vz-icon.vue';
import { useValidator } from '@shared/components/fields/helpers';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorResponse } from '@shared/services/api-service/models';
import { useTranslator } from '@/plugins/i18n/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-canvas-board-cropper',
  props: {
  hideDetails: { type: Boolean, default: false },
  autoSave: { type: Boolean, default: false },
  modelValue: { type: String as PropType<string | null | undefined>, required: true },
  disabled: { type: Boolean, default: false },
  file: { type: File as PropType<File | undefined>, default: undefined },
  label: { type: String as PropType<string | undefined>, default: undefined },
  height: { type: [Number, String], default: 300 },
  width: { type: [Number, String], default: 400 },
  megapixel: { type: [String, Number], default: 2 },
  backgroundColor: { type: String as PropType<string | null>, default: '#fff' },
  type: { type: String as PropType<`image/${'jpeg' | 'png'}`>, default: 'image/jpeg' },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  name: { type: String as PropType<string | undefined>, default: undefined },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
},
  emits: ['update:model-value', 'update:file'],
  setup(__props, { expose: __expose, emit: __emit }) {

const t = useTranslator();

const props = __props;

const emit = __emit;
const src = ref<string>('');
const tempSrc = ref<string>('');
const canvasBoardRef = ref();

const vModel = computed({
  get: (): string => props.modelValue || '',
  set: (value: string | null) => {
    emit('update:model-value', value);
    emit('update:file', value ? FileService.base64ToFile(value) : null);
  },
});

const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  computed(() => props.rules),
  props.name || props.label || 'COMPONENT_LABELS.IMAGE_CROPPER'
);

const externalError = computed(() => {
  if (!props.errorMessage) {
    return;
  }

  if (typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});

const onSave = (): void => {
  if (!tempSrc.value) {
    return;
  }

  vModel.value = tempSrc.value;
  tempSrc.value = '';
};

const onChange = ({ detail }: CustomEvent): void => {
  tempSrc.value = detail.base64;
};

const loadFile = async (file?: File): Promise<void> => {
  if (file) {
    src.value = await FileService.fileToBase64(file);
  }
};

const onUploadFile = async (): Promise<void> => {
  const files = await FileService.uploadFile({ accept: ['image/*'], multiple: false });

  await loadFile(files?.[0]);
};

const onFileDrop = async ({ detail }: CustomEvent): Promise<void> => {
  await loadFile(detail.files?.[0]);
};

const onClear = () => {
  src.value = '';
  tempSrc.value = '';
  canvasBoardRef.value?.remove();
  emit('update:model-value', null);
};

watch(
  () => tempSrc.value,
  (value) => {
    if (props.autoSave && value) {
      onSave();
    }
  }
);

__expose({ save: onSave });

return (_ctx: any,_cache: any) => {
  const _component_canvas_board = _resolveComponent("canvas-board")!
  const _component_file_drop = _resolveComponent("file-drop")!
  const _component_vz_button = _resolveComponent("vz-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "canvas-board-cropper",
    "data-errors": _unref(validateMessage)
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t(__props.label)), 1))
      : _createCommentVNode("", true),
    (vModel.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            src: vModel.value,
            height: __props.height,
            width: __props.width,
            alt: _ctx.$t(__props.label || 'COMPONENT_LABELS.IMAGE_CROPPER')
          }, null, 8, _hoisted_4),
          _createElementVNode("div", null, [
            _createVNode(VzIcon, {
              name: "svg:trash",
              color: "#fff",
              size: "2rem",
              onClick: onClear
            })
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "canvas-board-cropper__cropper mb-2",
          style: _normalizeStyle({ height: __props.height + 'px' })
        }, [
          _createVNode(_component_file_drop, {
            class: "fill-width",
            onUpdate: onFileDrop
          }, {
            default: _withCtx(() => [
              _createVNode(_component_canvas_board, {
                ref_key: "canvasBoardRef",
                ref: canvasBoardRef,
                src: src.value,
                width: __props.width,
                height: __props.height,
                "background-color": __props.backgroundColor || '',
                megapixel: __props.megapixel,
                type: __props.type,
                disabled: __props.disabled,
                onChange: onChange
              }, null, 8, ["src", "width", "height", "background-color", "megapixel", "type", "disabled"])
            ]),
            _: 1
          }),
          (!tempSrc.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_vz_button, {
                  "icon-name": "svg:plus",
                  type: "solid",
                  onClick: onUploadFile
                })
              ]))
            : _createCommentVNode("", true)
        ], 4)),
    _createElementVNode("div", {
      class: _normalizeClass(['vz-input__error', { 'vz-input__error--hidden': __props.hideDetails && !_unref(validateMessage) && !externalError.value }]),
      role: "alert"
    }, [
      (_unref(validateMessage))
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass({ 'vz-input__error-internal': !_unref(isTouched) })
          }, _toDisplayString(_ctx.$t(_unref(validateMessage))), 3))
        : (externalError.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t(externalError.value)), 1))
          : _createCommentVNode("", true)
    ], 2),
    (!__props.autoSave && !vModel.value && tempSrc.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_vz_button, {
            text: "GENERAL.SAVE",
            onClick: onSave
          }),
          _createVNode(_component_vz_button, {
            text: "GENERAL.CANCEL",
            onClick: onClear
          })
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}
}

})