import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

import type { BaseRecords } from '@/shared/models';
import { computed, defineAsyncComponent, type PropType } from 'vue';
import { useInfinityScroll, useServerErrorsMapper } from '@/shared/composables';
import { SplashName } from '@shared/components/svg-href/svg-splash.type';
import { DEFAULT_TABLE_PAGE_SIZE } from '@shared/components/tables/constants/data-table.constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-infinity-scroll',
  props: {
  disabled: { type: Boolean, default: false },
  hideEmptyState: { type: Boolean, default: false },
  hideFirstLoad: { type: Boolean, default: false },
  reverse: { type: Boolean, default: false },
  payload: { type: Object as PropType<Record<any, any>>, default: () => ({}) },
  callback: { type: Function as PropType<(...arg: any) => Promise<any>>, required: true },
  initial: { type: Object as PropType<BaseRecords<any>>, default: () => ({ page: { size: DEFAULT_TABLE_PAGE_SIZE }, data: null }) },
  items: { type: Array as PropType<any[]>, default: () => [] },
  disablePayloadWatcher: { type: Boolean, default: false },
  noDataImage: { type: String as PropType<SplashName>, default: 'no-data' },
  isHorizontal: { type: Boolean, default: false },
},
  emits: ['update:state'],
  setup(__props, { expose: __expose, emit: __emit }) {

const EmptyState = defineAsyncComponent(() => import(/* webpackChunkName: "empty-state" */ '@/components/empty-state.vue'));

const props = __props;

const emit = __emit;

const errors = computed(() => useServerErrorsMapper(error));

const { elementRef, data, loading, refreshing, error, push, update, remove, reset, scrollTo, isScrollAtBottom } = useInfinityScroll<any, any>(
  props.callback,
  {
    reverse: props.reverse,
    initial: props.initial,
    items: props.items,
    payload: computed(() => props.payload),
    disablePayloadWatcher: props.disablePayloadWatcher,
    callbackSuccess: (state) => emit('update:state', state),
    isHorizontal: computed(() => props.isHorizontal),
  }
);

__expose({ reset, scrollTo, push, update, remove, error, items: data, loading, isScrollAtBottom });

return (_ctx: any,_cache: any) => {
  const _component_vz_spinner = _resolveComponent("vz-spinner")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "elementRef",
    ref: elementRef,
    class: _normalizeClass(['infinity-scroll fill-height', { 'infinity-scroll--fill-height': !_unref(loading) && !_unref(data).length, 'overflow-hidden': _unref(loading) && !_unref(data).length }])
  }, [
    (_unref(refreshing) && !__props.reverse)
      ? (_openBlock(), _createBlock(_component_vz_spinner, {
          key: 0,
          class: "infinity-scroll__refreshing"
        }))
      : _createCommentVNode("", true),
    (_unref(loading) && !_unref(refreshing) && __props.reverse)
      ? (_openBlock(), _createBlock(_component_vz_spinner, {
          key: 1,
          class: _normalizeClass(['infinity-scroll__loading', { 'infinity-scroll__loading-hidden': __props.hideFirstLoad }])
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (_unref(error))
      ? _renderSlot(_ctx.$slots, "error", {
          key: 2,
          errors: _unref(error)
        }, () => [
          _createVNode(_unref(EmptyState), {
            "no-data-image": "server-error",
            errors: _unref(error)
          }, null, 8, ["errors"])
        ])
      : (!_unref(loading) && !_unref(data).length && !__props.initial?.page?.index && !__props.hideEmptyState)
        ? _renderSlot(_ctx.$slots, "no-data", { key: 3 }, () => [
            _createVNode(_unref(EmptyState), { "no-data-image": __props.noDataImage }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "empty-state-content")
              ]),
              _: 3
            }, 8, ["no-data-image"])
          ])
        : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
            (_ctx.$slots['header'])
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(['infinity-scroll__header', { 'infinity-scroll__header-sticky': !_unref(refreshing) }])
                }, [
                  _renderSlot(_ctx.$slots, "header", { errors: errors.value })
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.$slots['default'])
              ? _renderSlot(_ctx.$slots, "default", {
                  key: 1,
                  data: _unref(data),
                  errors: errors.value,
                  loading: _unref(loading)
                })
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data), (item, index) => {
              return _renderSlot(_ctx.$slots, "item", {
                key: index,
                item: item,
                index: index
              })
            }), 128))
          ], 64)),
    (_unref(loading) && !_unref(refreshing) && !__props.reverse)
      ? (_openBlock(), _createBlock(_component_vz_spinner, {
          key: 5,
          class: _normalizeClass(['infinity-scroll__loading', { 'infinity-scroll__loading-hidden': __props.hideFirstLoad }])
        }, null, 8, ["class"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})