import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vz-stepper"
}
const _hoisted_2 = { class: "d-flex flex-column gap-1 mb-2" }
const _hoisted_3 = {
  key: 0,
  class: "text-title-3 text-ellipsis"
}
const _hoisted_4 = {
  key: 1,
  class: "text-title-3 text-ellipsis"
}
const _hoisted_5 = {
  key: 2,
  class: "text-title-1 font-weight-400 text-ellipsis mt-1"
}
const _hoisted_6 = {
  key: 1,
  class: "vz-stepper__progress_bar"
}
const _hoisted_7 = {
  key: 2,
  class: "d-flex align-center justify-center flex-grow-1"
}
const _hoisted_8 = {
  key: 3,
  class: "d-flex align-center justify-center flex-grow-1"
}

import { computed, type PropType, ref, watch } from 'vue';
import type { StepComponent } from '@/shared/components/stepper/models/step-component';
import type { FormActionButtons } from '@shared/components/fields/vz-form/models';
import { stepperComponentMapper } from '@/shared/components/stepper/helpers/stepper-components-mapper';
import ServerError from '@shared/services/api-service/server-error';
import VzForm from '@shared/components/fields/vz-form/vz-form.vue';
import { useServerErrorsMapper } from '@shared/composables';
import { FormRef } from '@shared/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-stepper',
  props: {
  index: { type: Number, default: 0 },
  title: { type: String as PropType<string | undefined>, default: undefined },
  autocomplete: { type: String as PropType<'off' | 'on'>, default: 'off' },
  modelValue: { type: [Object, Number, String, Array], default: undefined },
  steps: { type: Array as PropType<Array<StepComponent>>, required: true },
  submitText: { type: String, default: 'GENERAL.SUBMIT' },
  submitCallback: { type: Function as PropType<() => Promise<void> | void | undefined>, default: undefined },
  cancelText: { type: String, default: 'GENERAL.CANCEL' },
  cancelCallback: { type: Function as PropType<() => Promise<void> | void | undefined>, default: undefined },
  cancelDisabled: { type: Boolean, default: false },
  hideProgressBar: { type: Boolean, default: false },
  hideErrors: { type: Boolean, default: false },
  errors: { type: Object as PropType<ServerError | null>, default: null },
  loading: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
},
  emits: ['update:model-value', 'update:step', 'step:previous', 'step:next', 'submit', 'cancel'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const vzFormRef = ref<FormRef>();
const serverError = useServerErrorsMapper(computed(() => props.errors || errors.value));

const activeSteps = computed(() => props.steps.filter(({ hide }) => !hide));

const actions = computed(
  (): FormActionButtons => [
    {
      text: !stepNumber.value ? props.cancelText : 'GENERAL.BACK',
      disabled: !stepNumber.value ? props.cancelDisabled : false,
      callback: onBack,
    },
    {
      text: isLastStep.value ? props.submitText : 'GENERAL.NEXT',
      validate: true,
      disabled: props.disabled,
      callback: onNext,
    },
  ]
);

const { formRef, dynamicComponent, nextStep, previousStep, stepNumber, isLastStep, loading, errors, fields } = stepperComponentMapper(activeSteps, {
  submit: async () => {
    await props.submitCallback?.();
    emit('submit');
  },
  cancel: async () => {
    await props.cancelCallback?.();
    emit('cancel');
  },
});

const onNext = async (): Promise<void> => {
  await nextStep();

  emit('step:next');
};

const onBack = (): void => {
  previousStep();
  emit('step:previous');
};

watch(
  () => props.index,
  (value) => (stepNumber.value = value),
  { immediate: true }
);

__expose({
  formRef,
  stepNumber,
  isLastStep,
  loading,
  errors,
  fields,
  onNext,
  onBack,
  validate: (isSilent?: boolean) => vzFormRef.value?.validate(isSilent),
  dynamicComponent,
});

return (_ctx: any,_cache: any) => {
  const _component_vz_error_alert = _resolveComponent("vz-error-alert")!
  const _component_vz_spinner = _resolveComponent("vz-spinner")!

  return (_unref(dynamicComponent))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_unref(dynamicComponent).title)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t(_unref(dynamicComponent).title)), 1))
            : (__props.title)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t(__props.title)), 1))
              : _createCommentVNode("", true),
          (_unref(dynamicComponent).subtitle)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t(_unref(dynamicComponent).subtitle)), 1))
            : _createCommentVNode("", true)
        ]),
        (!__props.hideErrors)
          ? (_openBlock(), _createBlock(_component_vz_error_alert, {
              key: 0,
              class: "vz-stepper__errors",
              errors: _unref(serverError)
            }, null, 8, ["errors"]))
          : _createCommentVNode("", true),
        (!_ctx.$slots['splash'] && !__props.hideProgressBar && activeSteps.value.length > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeSteps.value.length, (stepIndex) => {
                return (_openBlock(), _createElementBlock("div", _mergeProps({
                  key: stepIndex,
                  class: [
          'vz-stepper__progress_bar-item',
          {
            'vz-stepper__progress_bar-item--active': _unref(stepNumber) + 1 === stepIndex,
            'vz-stepper__progress_bar-item--passed': _unref(stepNumber) + 1 > stepIndex,
          },
        ]
                }, _toHandlers(_unref(stepNumber) + 1 > stepIndex ? { click: () => (stepNumber.value = stepIndex - 1) } : {}, true)), _toDisplayString(stepIndex), 17))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_unref(loading))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_vz_spinner, { size: "96px" })
            ]))
          : (_ctx.$slots['splash'])
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "splash")
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _renderSlot(_ctx.$slots, _unref(dynamicComponent).name ? `${_unref(dynamicComponent).name}-header` : `h${_unref(stepNumber) + 1}`),
                _createVNode(VzForm, {
                  ref_key: "vzFormRef",
                  ref: vzFormRef,
                  class: _normalizeClass(`pa-2 vz-form-content__${_unref(dynamicComponent).name || 'content'}`),
                  actions: actions.value,
                  disabled: _unref(loading)
                }, _createSlots({
                  default: _withCtx(() => [
                    (_unref(dynamicComponent).component)
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(dynamicComponent).component), _mergeProps({
                          key: 0,
                          "field-message": _unref(fields),
                          "model-value": __props.modelValue,
                          errors: _unref(errors)
                        }, _unref(dynamicComponent).props, {
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (emit('update:model-value', $event)))
                        }), null, 16, ["field-message", "model-value", "errors"]))
                      : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, _unref(dynamicComponent).name || _unref(stepNumber) + 1, { fieldMessage: _unref(fields) })
                  ]),
                  _: 2
                }, [
                  (_ctx.$slots['actions'])
                    ? {
                        name: "extra-actions",
                        fn: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "actions", {
                            step: _unref(stepNumber),
                            disabled: __props.disabled || _unref(dynamicComponent).disabled
                          })
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["class", "actions", "disabled"])
              ], 64))
      ]))
    : _createCommentVNode("", true)
}
}

})