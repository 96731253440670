<template>
  <div :class="['vz-popover', { 'vz-popover--background': isActive && dim }]">
    <div
      ref="popoverRef"
      :class="['vz-popover__activator', { 'vz-popover__activator--disabled': disabled }]"
      role="button"
      @mousedown="onPressStart"
      @mouseup="onPressEnd"
      @mouseleave="onPressCancel"
      @touchstart="onPressStart"
      @touchend="onPressEnd"
      @touchcancel="onPressCancel"
      @dblclick="$emit('dblclick')"
      @click="onOpen"
    >
      <slot name="activator">
        <vz-icon :name="iconName" size="1.5rem" :color="isActive ? 'primary-700' : 'primary-900'" />
      </slot>
    </div>

    <div
      v-if="isActive && !disabled && ($slots['top'] || $slots['default'] || $slots['bottom'])"
      ref="popoverContent"
      class="vz-popover__content"
      :style="popoverStyle"
    >
      <slot :close="onClose" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@shared/components/icon/icon.type';
import { computed, type PropType, ref } from 'vue';
import { isRtl } from '@/plugins/i18n/helpers';
import { getLastZIndex } from '@shared/helpers';

const props = defineProps({
  hold: { type: [Boolean, Number] as PropType<number | boolean>, default: false },
  dim: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  size: { type: [String, Number], default: 42 },
  offsetX: { type: [String, Number], default: 4 },
  offsetY: { type: [String, Number], default: 0 },
  closeTimeout: { type: Number, default: 0 },
  iconName: { type: String as PropType<IconName>, default: 'svg:menu' },
});

const emit = defineEmits(['click', 'dblclick']);

const isActive = ref<boolean>(false);
const zIndex = ref<number>(getLastZIndex() + 1);
const popoverRef = ref<HTMLDivElement>();
const popoverContent = ref<HTMLDivElement>();
const loadingIndex = ref<number | null>(null);
const pressTimer = ref<ReturnType<typeof setTimeout> | undefined>(undefined);
const closeTimer = ref<ReturnType<typeof setTimeout> | undefined>(undefined);
const ignoreNextClick = ref(false);
const lastTap = ref(0);

const popoverStyle = computed(() => {
  if (!popoverRef.value || !popoverContent.value) {
    return {};
  }

  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  const { offsetLeft: mainLeft, offsetTop: mainTop, clientHeight: mainHeight, clientWidth: mainWidth } = popoverRef.value;
  const { clientWidth: contentWidth } = popoverContent.value;

  const isLtr = !isRtl();
  const positionX = isLtr ? mainLeft + +props.offsetX : mainLeft + (mainWidth - contentWidth) - +props.offsetX;

  const targetTop = mainTop + mainHeight + 4 + +props.offsetY;
  const targetLeft = contentWidth + positionX < windowWidth ? Math.max(positionX, 4) + 'px' : 'initial';
  const targetRight = contentWidth + positionX > windowWidth ? Math.min(windowWidth - positionX - mainWidth, windowWidth) + 'px' : 'initial';

  return {
    top: targetTop + 'px',
    left: targetLeft,
    right: targetRight,
    maxHeight: windowHeight - targetTop - 16 + 'px',
    zIndex: zIndex.value,
  };
});

const onClose = (ev?: Event) => {
  if (ignoreNextClick.value && ev) {
    ignoreNextClick.value = false;

    return;
  }

  if (ev && (popoverRef.value?.contains(ev?.target as Node) || popoverContent.value?.contains(ev?.target as Node))) {
    return;
  }

  isActive.value = false;
  window.removeEventListener('click', onClose);
  window.removeEventListener('touchstart', onClose);
};

const onOpen = (ev?: Event): void => {
  if ((props.hold && ev) || props.disabled) {
    emit('click');

    return;
  }

  ev?.preventDefault();

  isActive.value = true;
  loadingIndex.value = null;
  zIndex.value = getLastZIndex() + 1;

  window.addEventListener('click', onClose);
  window.addEventListener('touchstart', onClose);

  if (props.closeTimeout) {
    clearTimeout(closeTimer.value);
    closeTimer.value = setTimeout(() => onClose(), props.closeTimeout);
  }
};

const onPressStart = (ev: Event) => {
  emit('click');

  const currentTime = new Date().getTime();
  const tapLength = currentTime - lastTap.value;

  if (tapLength < 300 && tapLength > 0) {
    emit('dblclick');
  }

  lastTap.value = currentTime;

  if (props.hold) {
    ev.preventDefault();
  }

  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }

  pressTimer.value = setTimeout(onOpen, typeof props.hold === 'number' ? props.hold : 500);
};

const onPressEnd = () => {
  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }
};

const onPressCancel = () => {
  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
  }
};

defineExpose({ close: onClose, open: onOpen });
</script>

<style lang="scss">
.vz-popover {
  &:after {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &__activator {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(&--disabled) {
      cursor: pointer;
    }
  }

  &__content {
    border-radius: var(--border-radius-regular);
    padding: 0.25rem 0.5rem;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1000;
    background-color: var(--color-background-regular);
    box-shadow: var(--shadow-menu);
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 180px;

    .vz-button__content {
      justify-content: flex-start !important;
      width: 100%;
    }
  }

  &--background {
    &:after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.5;
    }
  }
}
</style>
