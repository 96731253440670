<template>
  <div
    v-scroll-to-view="[scrollOnChange, behavior]"
    :class="[
      'vz-card',
      {
        'vz-card--editing': isEditSlotEnabled,
        'vz-card--clickable': !nonClickable && !isEditSlotEnabled,
        'vz-card--flat': (Array.isArray(flat) && flat.includes(cardMode)) || flat === true,
        skeleton: loading,
      },
    ]"
    @click="onClick"
  >
    <div v-if="showToolbar" class="vz-card__toolbar">
      <vz-button
        v-if="removable || deleteCallback"
        color="red-700"
        icon-name="svg:trash"
        icon-size="1rem"
        background-color="mono-100"
        type="solid"
        :text="showLabels ? 'GENERAL.REMOVE' : undefined"
        @click.stop="onRemove"
      />

      <vz-button
        v-if="$slots['edit'] || updateCallback"
        color="primary-900"
        icon-name="svg:edit"
        icon-size="1rem"
        background-color="mono-100"
        type="solid"
        :text="showLabels ? 'GENERAL.EDIT' : undefined"
        @click.stop="onEdit"
      />

      <slot name="toolbar" />
    </div>

    <slot name="default" />

    <slot v-if="$slots.static" name="static" />

    <template v-if="$slots.view || $slots.edit || $slots.remove">
      <slot v-if="isViewSlotEnabled" name="view" />

      <template v-else-if="isEditSlotEnabled">
        <form ref="formRef" class="flex-grow-1 overflow-y-auto" role="form" autocomplete="off" @dblclick.stop.prevent @submit.prevent>
          <vz-error-alert :errors="updateCallbackRequest.error.value" />

          <slot name="edit" :errors="errors" :form-ref="formRef" />
        </form>

        <div v-key-down="onKeyDown" class="d-flex gap-2 justify-end">
          <vz-button :text="!immediateUpdate ? 'GENERAL.SAVE' : 'GENERAL.CLOSE'" :loading="updateCallbackRequest.loading.value" @click="onSubmit" />

          <vz-button v-if="!immediateUpdate" text="GENERAL.CANCEL" :disabled="updateCallbackRequest.loading.value" @click="onCancel" />
        </div>
      </template>
    </template>

    <slot name="bottom" />
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType, ref, useSlots } from 'vue';
import { useAsync, useServerErrorsMapper } from '@/shared/composables';
import { useFormValidator } from '@/shared/components/fields/helpers';
import VzErrorAlert from '@shared/components/vz-error-alert.vue';

enum CardMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

const props = defineProps({
  scrollOnChange: { type: Boolean, default: false },
  behavior: { type: String as PropType<ScrollBehavior>, default: 'smooth' },
  removable: { type: Boolean, default: false },
  editable: { type: Boolean, default: true },
  loading: { type: Boolean, default: false },
  nonClickable: { type: Boolean, default: false },
  showLabels: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  flat: { type: [Boolean, Array] as PropType<boolean | Array<keyof typeof CardMode>>, default: false },
  updateCallback: { type: Function as PropType<(...arg: any) => Promise<any> | any>, default: undefined },
  updatePayload: { type: Object as PropType<any>, default: () => ({}) },
  deleteCallback: { type: Function as PropType<() => Promise<void> | undefined>, default: undefined },
  immediateUpdate: { type: Boolean, default: false },
});

const emit = defineEmits(['edit', 'remove', 'click', 'load', 'update']);
const slots = useSlots();

const updateCallbackRequest = useAsync(props.updateCallback || (() => {}), {
  successCallback: () => {
    setTimeout(() => (cardMode.value = CardMode.VIEW));
    emit('edit', false);
  },
  errorsCleanTimeout: 30 * 1000,
});

const cardMode = ref<CardMode>(CardMode.VIEW);
const formRef = ref<Element | undefined>(undefined);
const errors = useServerErrorsMapper(updateCallbackRequest.error);

const showToolbar = computed(() => {
  const isDisabled = props.disabled || cardMode.value !== CardMode.VIEW;
  const isEditable = props.editable && (slots['edit'] || props.updateCallback);
  const isRemovable = props.removable || props.deleteCallback;
  const hasToolbarSlot = slots['toolbar'];

  return !isDisabled && (isEditable || isRemovable || hasToolbarSlot);
});

const isViewSlotEnabled = computed(() => cardMode.value === CardMode.VIEW && slots['view']);
const isEditSlotEnabled = computed(() => cardMode.value === CardMode.EDIT && slots['edit']);

const onEdit = (): void => {
  if (!slots['edit']) {
    updateCallbackRequest.call(props.updatePayload);

    return;
  }

  cardMode.value = cardMode.value === CardMode.EDIT ? CardMode.VIEW : CardMode.EDIT;
  emit('edit', cardMode.value === CardMode.EDIT);
};

const onRemove = (): void => {
  if (props.deleteCallback) {
    props.deleteCallback();
  } else {
    emit('remove');
  }
};

const onClick = (ev: Event): void => {
  if (props.nonClickable) {
    return;
  }

  if (cardMode.value !== CardMode.VIEW) {
    ev.stopPropagation();
    ev.preventDefault();
  } else {
    emit('click');
  }
};

const onSubmit = async (ev?: Event): Promise<void> => {
  ev?.stopPropagation();
  ev?.preventDefault();

  const isValid = useFormValidator(formRef);

  if (!isValid()) {
    return;
  }

  await updateCallbackRequest.call(props.updatePayload);
};

const onCancel = (ev?: Event): void => {
  ev?.stopPropagation();
  ev?.preventDefault();

  if (cardMode.value === CardMode.EDIT) {
    setTimeout(() => (cardMode.value = CardMode.VIEW));
    emit('edit', false);
  }
};

const onKeyDown = (ev: KeyboardEvent): void => {
  if (ev.key === 'Escape' && cardMode.value === CardMode.EDIT) {
    onSubmit(ev);
  }
};

defineExpose({
  onEdit,
  onRemove,
  disabled: computed(() => cardMode.value !== CardMode.VIEW),
  isEditActive: computed(() => cardMode.value === CardMode.EDIT),
});
</script>

<style lang="scss">
.vz-card {
  position: relative;

  &:not(&--flat) {
    background: var(--color-background-light);
    border: var(--border-regular);
    border-radius: var(--border-radius-regular);
    box-shadow: var(--shadow-light);
    padding: var(--card-padding);
  }

  &--clickable {
    cursor: pointer;
  }

  &__toolbar {
    display: flex;
    justify-content: flex-end;
    gap: 0.25rem;
    transition: 0.5s;
    width: 100%;
    position: absolute;
    top: 1rem;
    z-index: 100;
    @include inline-end(1.5rem);

    @include hover-support {
      height: 0;
      opacity: 0;
    }
  }

  &:hover {
    @include hover-support {
      .vz-card__toolbar {
        opacity: 1;
        height: 2.5rem;
      }
    }
  }
}
</style>
