<template>
  <vz-popover v-if="!showActionForOneItem || !hasOneItem" dim :icon-name="iconName">
    <template v-if="$slots['activator']" #activator>
      <slot name="activator" />
    </template>

    <template #default="{ close }">
      <slot name="top" />

      <div v-for="(item, index) in itemList" :key="index">
        <vz-divider v-if="item.divider === 'before'" class="mt-1" />

        <vz-button
          class="text-title-1 my-2"
          type="flat"
          icon-color="primary-900"
          :uppercase="uppercase"
          :loading="loadingIndex === index"
          :icon-name="item.icon?.name"
          :icon-type="item.icon?.type || iconType"
          :icon-size="item.icon?.size || '1.5rem'"
          :text="item.text"
          @click="onSelect(item.click, index, close)"
        />

        <vz-divider v-if="item.divider === 'after'" />
      </div>

      <slot name="bottom" />
    </template>
  </vz-popover>

  <vz-button
    v-else
    type="flat"
    icon-color="primary-900"
    :uppercase="uppercase"
    :loading="loadingIndex === 0"
    :icon-name="firstItem.icon?.name"
    :icon-type="firstItem.icon?.type || iconType"
    :aria-label="firstItem.text"
    @click="onSelect(firstItem.click, 0)"
  />
</template>

<script setup lang="ts">
import { computed, type PropType, ref } from 'vue';
import { MenuItem } from '@/shared/components/menus/models/menu-item';
import { type IconName, IconType } from '@shared/components/icon/icon.type';
import VzPopover from '@shared/components/menus/vz-popover.vue';

const props = defineProps({
  dim: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  items: { type: Array as PropType<Array<MenuItem>>, default: () => [] },
  size: { type: [String, Number], default: 42 },
  offsetX: { type: [String, Number], default: 4 },
  offsetY: { type: [String, Number], default: 0 },
  uppercase: { type: Boolean, default: true },
  iconType: { type: String as PropType<IconType>, default: 'solid' },
  showActionForOneItem: { type: Boolean, default: false },
  iconName: { type: String as PropType<IconName>, default: 'svg:menu' },
});

const loadingIndex = ref<number | null>(null);

const itemList = computed(() => (props.items || []).filter((item) => !item.hidden));
const hasOneItem = computed(() => itemList.value.length === 1);

const firstItem = computed(() => itemList.value[0]);

const onSelect = async (callback: () => unknown | Promise<unknown>, index: number, closeCallback?: () => void): Promise<void> => {
  try {
    loadingIndex.value = index;
    await callback();
    closeCallback?.();
  } finally {
    loadingIndex.value = null;
  }
};
</script>
