<template>
  <div
    ref="elementRef"
    :class="['infinity-scroll fill-height', { 'infinity-scroll--fill-height': !loading && !data.length, 'overflow-hidden': loading && !data.length }]"
  >
    <vz-spinner v-if="refreshing && !reverse" class="infinity-scroll__refreshing" />

    <vz-spinner
      v-if="loading && !refreshing && reverse"
      :class="['infinity-scroll__loading', { 'infinity-scroll__loading-hidden': hideFirstLoad }]"
    />

    <slot v-if="error" name="error" :errors="error">
      <empty-state no-data-image="server-error" :errors="error" />
    </slot>

    <slot v-else-if="!loading && !data.length && !initial?.page?.index && !hideEmptyState" name="no-data">
      <empty-state :no-data-image="noDataImage">
        <slot name="empty-state-content" />
      </empty-state>
    </slot>

    <template v-else>
      <div v-if="$slots['header']" :class="['infinity-scroll__header', { 'infinity-scroll__header-sticky': !refreshing }]">
        <slot name="header" :errors="errors" />
      </div>

      <slot v-if="$slots['default']" :data="data" :errors="errors" :loading="loading" />

      <slot v-for="(item, index) in data" :key="index" name="item" :item="item" :index="index" />
    </template>

    <vz-spinner
      v-if="loading && !refreshing && !reverse"
      :class="['infinity-scroll__loading', { 'infinity-scroll__loading-hidden': hideFirstLoad }]"
    />
  </div>
</template>

<script setup lang="ts">
import type { BaseRecords } from '@/shared/models';
import { computed, defineAsyncComponent, type PropType } from 'vue';
import { useInfinityScroll, useServerErrorsMapper } from '@/shared/composables';
import { SplashName } from '@shared/components/svg-href/svg-splash.type';
import { DEFAULT_TABLE_PAGE_SIZE } from '@shared/components/tables/constants/data-table.constants';

const EmptyState = defineAsyncComponent(() => import(/* webpackChunkName: "empty-state" */ '@/components/empty-state.vue'));

const props = defineProps({
  disabled: { type: Boolean, default: false },
  hideEmptyState: { type: Boolean, default: false },
  hideFirstLoad: { type: Boolean, default: false },
  reverse: { type: Boolean, default: false },
  payload: { type: Object as PropType<Record<any, any>>, default: () => ({}) },
  callback: { type: Function as PropType<(...arg: any) => Promise<any>>, required: true },
  initial: { type: Object as PropType<BaseRecords<any>>, default: () => ({ page: { size: DEFAULT_TABLE_PAGE_SIZE }, data: null }) },
  items: { type: Array as PropType<any[]>, default: () => [] },
  disablePayloadWatcher: { type: Boolean, default: false },
  noDataImage: { type: String as PropType<SplashName>, default: 'no-data' },
  isHorizontal: { type: Boolean, default: false },
});

const emit = defineEmits(['update:state']);

const errors = computed(() => useServerErrorsMapper(error));

const { elementRef, data, loading, refreshing, error, push, update, remove, reset, scrollTo, isScrollAtBottom } = useInfinityScroll<any, any>(
  props.callback,
  {
    reverse: props.reverse,
    initial: props.initial,
    items: props.items,
    payload: computed(() => props.payload),
    disablePayloadWatcher: props.disablePayloadWatcher,
    callbackSuccess: (state) => emit('update:state', state),
    isHorizontal: computed(() => props.isHorizontal),
  }
);

defineExpose({ reset, scrollTo, push, update, remove, error, items: data, loading, isScrollAtBottom });
</script>

<style scoped lang="scss">
@keyframes show-refreshing {
  from {
    transform: translate(-50%, -100%);
  }
  to {
    transform: translate(-50%, 10%);
  }
}

.infinity-scroll {
  position: relative;
  flex-grow: 1;
  padding-top: 0;
  transition: padding-top 0.3s;

  &:not(:hover) {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  &--fill-height {
    display: initial !important;
    overflow: hidden;
  }

  &:has(&__refreshing) {
    padding-top: 3rem;
  }

  &__header {
    &-sticky {
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }

  &__refreshing {
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translate(-50%, 10%);
    animation: show-refreshing 0.3s ease-in-out;
  }

  &__loading {
    &-hidden {
      display: none;
    }
  }

  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 1rem;
    padding: 0 2rem;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      object-position: 50% 10%;
    }
  }
}
</style>
