import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "vz-data-table__table-options"
}
const _hoisted_2 = {
  ref: "tableContainerRef",
  class: "vz-data-table__table-container"
}
const _hoisted_3 = { class: "text-title-1" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "d-flex align-center justify-center"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "vz-data-table__menu-action"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "vz-data-table__item-options"
}
const _hoisted_10 = {
  key: 0,
  class: "border-top-medium"
}
const _hoisted_11 = {
  key: 3,
  flat: "",
  class: "vz-data-table vz-data-table--no-data text-title-1"
}

import type { ErrorResponse } from '@/shared/services/api-service/models';
import type { SplashName } from '@shared/components/svg-href/svg-splash.type';
import type { BasePagination, BaseRecords } from '@shared/models';
import { TableHeader } from '@shared/components/tables/models';
import { computed, defineAsyncComponent, nextTick, onMounted, type PropType, ref, useSlots } from 'vue';
import { get } from 'lodash';
import { useAsync, useServerErrorsMapper } from '@/shared/composables';
import { LayoutEnum } from '@shared/directives/layout.enum';
import { MenuItem } from '@shared/components/menus/models/menu-item';
import { TableItemEnum } from '@shared/components/tables/constants/data-table.enum';

type TableSort = Record<string, { type: -1 | 1; fields: Array<string> }>;


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-data-table',
  props: {
  hidePagination: { type: Boolean, default: false },
  autoFetch: { type: Boolean, default: true },
  flat: { type: Boolean, default: false },
  readable: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false },
  headers: { type: Array as PropType<Array<TableHeader>>, required: true },
  items: { type: Array as PropType<Array<{ [key: string]: any }>>, required: true },
  page: { type: Number, default: 0 },
  sort: { type: Object as PropType<BasePagination['sort']>, default: () => ({}) },
  totalItems: { type: Number, default: 0 },
  loading: { type: Boolean, default: false },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  rowClickable: { type: [Boolean, Function] as PropType<boolean | ((item: any) => boolean)>, default: true },
  idKey: { type: String, default: '' },
  hideEmptyState: { type: Boolean, default: false },
  noDataText: { type: String, default: '' },
  noDataImage: { type: String as PropType<SplashName>, default: 'search-for-results' },
  addItemLabel: { type: String, default: 'GENERAL.ADD' },
  noResultsImage: { type: String as PropType<SplashName>, default: 'no-results' },
  isReadonlyCallback: { type: Function as PropType<(item: any, type?: keyof typeof TableItemEnum) => boolean>, default: () => false },
  hideSearchControls: { type: Boolean, default: false },
  // item actions
  updateCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  deleteCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  duplicateCallback: { type: Function as PropType<((item: any) => unknown | Promise<unknown>) | undefined>, default: undefined },
  // table actions
  tableActions: { type: Array as PropType<Array<MenuItem> | undefined>, default: undefined },
  createCallback: { type: Function as PropType<(() => unknown | Promise<unknown>) | undefined>, default: undefined },
  exportCallback: {
    type: Function as PropType<((items: Array<{ [key: string]: any }>, headers?: Array<TableHeader>) => unknown | Promise<unknown>) | undefined>,
    default: undefined,
  },
  hasFilters: { type: Boolean, default: false },
  fetchCallback: { type: Function as PropType<((page?: BasePagination) => Promise<BaseRecords<any>>) | undefined>, default: undefined },
  breakpoint: {
    type: String as PropType<LayoutEnum | null>,
    default: LayoutEnum.md,
    validator: (value: LayoutEnum | null) => value === null || Object.values(LayoutEnum).includes(value),
  },
},
  emits: ['search', 'clear', 'update:page', 'update:sort', 'select:item', 'update:item', 'duplicate:item', 'delete:item'],
  setup(__props, { emit: __emit }) {

const EmptyState = defineAsyncComponent(() => import(/* webpackChunkName: "empty-state" */ '@/components/empty-state.vue'));

const props = __props;

const emit = __emit;
const { call: submitRequest, loading: submitLoading, error: submitError, results: submitResults } = useAsync(props.fetchCallback);

const slots = useSlots();
const searchTriggered = ref<boolean>(false);
const layout = ref<LayoutEnum>(LayoutEnum.xl);

const tableHeaders = computed(
  (): Array<TableHeader> => props.headers?.filter(({ hidden }) => (Array.isArray(hidden) ? !hidden.includes(layout.value) : !hidden)) || []
);

const isActionEnabled = (item: any, type: keyof typeof TableItemEnum) => {
  const map = {
    [TableItemEnum.EDIT]: !!props.updateCallback,
    [TableItemEnum.DELETE]: !!props.deleteCallback,
    [TableItemEnum.DUPLICATE]: !!props.duplicateCallback,
  };

  return map[type] && !props.isReadonlyCallback(item, type);
};

const isItemOptionsEnabled = computed(() => !!(props.updateCallback || props.deleteCallback));

const menuActions = computed((): Array<MenuItem> => {
  const actions: Array<MenuItem> = [];

  if (props.createCallback) {
    actions.push({ text: 'GENERAL.ADD', icon: { name: 'svg:plus' }, click: props.createCallback });
  }

  if (props.exportCallback) {
    actions.push({ text: 'GENERAL.EXPORT', click: () => props.exportCallback?.(anyItems.value, props.headers) });
  }

  return [...actions, ...(props.tableActions || [])];
});

const splashImage = computed((): SplashName => {
  if (anyErrors.value?.errorMessage?.length) {
    return 'server-error';
  }

  return searchTriggered.value ? props.noResultsImage : props.noDataImage;
});

const defaultNoDataText = computed(() => {
  if (!slots['search-panel']) {
    return 'DATA.NO_DATA_AVAILABLE';
  }

  return searchTriggered.value || props.hasFilters ? 'DATA.NO_SEARCH_RESULTS' : 'DATA.SEARCH_FOR_RESULTS';
});

const anyItems = computed((): BaseRecords<any>['data'] => props.items || submitResults.value);
const anyErrors = computed(() => props.errors || submitError.value);
const anyLoading = computed(() => props.loading || submitLoading.value);

const serverErrors = useServerErrorsMapper(anyErrors);
const internalPage = ref<number>(0);

const vPage = computed({
  get: (): number => (props.fetchCallback ? internalPage.value : props.page),
  set: (value: number) => {
    internalPage.value = value;
    submitRequest?.({ index: value });
    emit('update:page', value);
  },
});

const vSort = computed({
  get: (): TableSort => {
    return (props.headers || []).reduce((acc: TableSort, { sortable, value }) => {
      const sort = props.sort || {};

      if (Array.isArray(sortable)) {
        const arrSort = Object.entries(sort);

        const type = arrSort.find(([key]) => {
          return sortable.includes(key);
        })?.[1];

        return type ? { ...acc, [value]: { type: type, fields: sortable } } : acc;
      } else if (sortable === true) {
        return sort[value] ? { ...acc, [value]: { type: sort[value], fields: [value] } } : acc;
      }

      return acc;
    }, {});
  },
  set: (value) => {
    const sort = Object.values(value).reduce((acc: Record<string, -1 | 1>, { type, fields }) => {
      fields
        .filter((key) => !!key)
        .forEach((key) => {
          acc = { ...acc, [key]: type };
        });

      return acc;
    }, {});

    submitRequest?.({ sort });
    emit('update:sort', sort);
  },
});

const onSort = (key: string, sortable?: boolean | Array<string>) => {
  if (!sortable) {
    return;
  }

  if (!vSort.value[key]?.type) {
    vSort.value = { [key]: { type: 1, fields: Array.isArray(sortable) ? sortable : [key] } };
  } else if (vSort.value[key]?.type === 1) {
    vSort.value = { [key]: { type: -1, fields: Array.isArray(sortable) ? sortable : [key] } };
  } else {
    vSort.value = {};
  }
};

const isClickable = (item: any) => {
  return !anyLoading.value && ((props.rowClickable instanceof Function && props.rowClickable(item)) || props.rowClickable === true);
};

const onSelect = (item: any) => {
  if (anyLoading.value || !isClickable(item)) {
    return;
  }

  emit('select:item', props.idKey ? item[props.idKey] : item);
};

const onSearch = async (): Promise<void> => {
  await submitRequest?.();
  searchTriggered.value = true;
  emit('search');
};

const onClear = async (): Promise<void> => {
  searchTriggered.value = false;
  emit('clear');

  nextTick(submitRequest);
};

const onEdit = async (item: any): Promise<void> => {
  await props.updateCallback?.(item);
  emit('update:item', item);
};

const onDuplicate = async (item: any): Promise<void> => {
  await props.duplicateCallback?.(item);
  emit('duplicate:item', item);
};

const onDelete = async (item: any): Promise<void> => {
  await props.deleteCallback?.(item);
  emit('delete:item', item);
};

onMounted(() => {
  if (props.autoFetch) {
    onSearch();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_vz_search_panel = _resolveComponent("vz-search-panel")!
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_popover_menu = _resolveComponent("vz-popover-menu")!
  const _component_vz_button = _resolveComponent("vz-button")!
  const _component_vz_tab_switcher = _resolveComponent("vz-tab-switcher")!
  const _directive_layout = _resolveDirective("layout")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['vz-data-table', { 'vz-data-table--loading': anyLoading.value }])
  }, [
    (_ctx.$slots['search-panel'])
      ? (_openBlock(), _createBlock(_component_vz_search_panel, {
          key: 0,
          class: "pb-4",
          "hide-controls": __props.hideSearchControls,
          onClear: onClear,
          onSearch: onSearch
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "search-panel", { errors: _unref(serverErrors) })
          ]),
          _: 3
        }, 8, ["hide-controls"]))
      : _createCommentVNode("", true),
    (_ctx.$slots['table-options'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "table-options")
        ]))
      : _createCommentVNode("", true),
    (anyItems.value.length || anyLoading.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("table", null, [
              _createElementVNode("thead", _hoisted_3, [
                _createElementVNode("tr", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableHeaders.value, ({ ...header }, index) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: index,
                      class: _normalizeClass(`vz-data-table__data-${header.value}`)
                    }, [
                      _renderSlot(_ctx.$slots, `header-${header.value}`, { header: header }, () => [
                        _createElementVNode("div", {
                          class: _normalizeClass([
                      'text-ellipsis',
                      {
                        clickable: header.sortable,
                        'd-flex gap-1': header.sortable,
                        'text-center justify-center': header.center,
                        'ps-4': header.center && header.sortable,
                      },
                    ]),
                          onClick: ($event: any) => (onSort(header.value, header.sortable))
                        }, [
                          _renderSlot(_ctx.$slots, `label-${header.value}`, {}, () => [
                            _createTextVNode(_toDisplayString(_ctx.$t(header.title)), 1)
                          ]),
                          (vSort.value[header.value] || header.sortable)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                (vSort.value[header.value])
                                  ? (_openBlock(), _createBlock(_component_vz_icon, {
                                      key: 0,
                                      name: `svg:sort-${vSort.value[header.value].type === 1 ? 'up' : 'down'}`
                                    }, null, 8, ["name"]))
                                  : (header.sortable)
                                    ? (_openBlock(), _createBlock(_component_vz_icon, {
                                        key: 1,
                                        name: "svg:sort",
                                        "fill-opacity": "0.1"
                                      }))
                                    : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ], 10, _hoisted_4)
                      ])
                    ], 2))
                  }), 128)),
                  (isItemOptionsEnabled.value || _ctx.$slots['item-options'])
                    ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
                        _renderSlot(_ctx.$slots, "item-options-header", {}, () => [
                          _createTextVNode(_toDisplayString(_ctx.$t('GENERAL.ACTIONS')), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (menuActions.value.length)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                        _createVNode(_component_vz_popover_menu, {
                          "show-action-for-one-item": "",
                          "icon-type": "regular",
                          "icon-name": "svg:vdots",
                          items: menuActions.value
                        }, null, 8, ["items"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(anyItems.value, ({ ...item }, itemIndex) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: itemIndex,
                    class: _normalizeClass({ 'vz-data-table__table-container--row-clickable': isClickable(item) && __props.readable }),
                    onClick: ($event: any) => (__props.readable ? onSelect(item) : undefined)
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableHeaders.value, ({ style, ...header }, columIndex) => {
                      return (_openBlock(), _createElementBlock("td", {
                        key: columIndex,
                        class: _normalizeClass(`vz-data-table__data-${header.value} relative`),
                        style: _normalizeStyle(style)
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass([
                    'vz-data-table__cell d-flex fill-height',
                    { 'text-center justify-center': header.center },
                    ...(Array.isArray(header.class) ? header.class : [header.class]),
                  ])
                        }, [
                          _renderSlot(_ctx.$slots, header.value, {
                            item: item,
                            itemIndex: itemIndex,
                            colIndex: columIndex
                          }, () => [
                            _createTextVNode(_toDisplayString(_unref(get)(item, header.value, null)), 1)
                          ])
                        ], 2)
                      ], 6))
                    }), 128)),
                    (isItemOptionsEnabled.value || _ctx.$slots['item-options'])
                      ? (_openBlock(), _createElementBlock("td", _hoisted_9, [
                          _createElementVNode("div", {
                            class: "d-flex gap-2 align-center",
                            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                          }, [
                            _renderSlot(_ctx.$slots, "item-options", {
                              item: item,
                              itemIndex: itemIndex
                            }),
                            (__props.updateCallback)
                              ? (_openBlock(), _createBlock(_component_vz_button, {
                                  key: 0,
                                  "icon-name": "svg:edit",
                                  "icon-type": "regular",
                                  "aria-label": "GENERAL.EDIT",
                                  disabled: __props.disabled || !isActionEnabled(item, _unref(TableItemEnum).EDIT),
                                  onClick: ($event: any) => (onEdit(item))
                                }, null, 8, ["disabled", "onClick"]))
                              : _createCommentVNode("", true),
                            (__props.duplicateCallback)
                              ? (_openBlock(), _createBlock(_component_vz_button, {
                                  key: 1,
                                  "icon-name": "svg:copy",
                                  "icon-type": "regular",
                                  "aria-label": "GENERAL.DUPLICATE",
                                  disabled: __props.disabled || !isActionEnabled(item, _unref(TableItemEnum).DUPLICATE),
                                  onClick: ($event: any) => (onDuplicate(item))
                                }, null, 8, ["disabled", "onClick"]))
                              : _createCommentVNode("", true),
                            (__props.deleteCallback)
                              ? (_openBlock(), _createBlock(_component_vz_button, {
                                  key: 2,
                                  color: "red-900",
                                  "icon-name": "svg:trash",
                                  "icon-type": "regular",
                                  "aria-label": "GENERAL.DELETE",
                                  disabled: __props.disabled || !isActionEnabled(item, _unref(TableItemEnum).DELETE),
                                  onClick: ($event: any) => (onDelete(item))
                                }, null, 8, ["disabled", "onClick"]))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_8))
                }), 128))
              ])
            ])
          ], 512),
          (!__props.hidePagination && __props.totalItems > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_vz_tab_switcher, {
                  index: vPage.value,
                  "onUpdate:index": _cache[1] || (_cache[1] = ($event: any) => ((vPage).value = $event)),
                  class: "vz-data-table__pagination",
                  tabs: __props.totalItems || 0,
                  "num-of-shown": "10",
                  flat: ""
                }, null, 8, ["index", "tabs"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : (!anyLoading.value && !__props.hideEmptyState)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _renderSlot(_ctx.$slots, "no-data", { errors: anyErrors.value }, () => [
              _createVNode(_unref(EmptyState), {
                errors: anyErrors.value,
                "no-data-text": __props.noDataText || defaultNoDataText.value,
                "no-data-image": splashImage.value
              }, {
                default: _withCtx(() => [
                  (__props.createCallback)
                    ? (_openBlock(), _createBlock(_component_vz_button, {
                        key: 0,
                        class: "mt-2 px-10",
                        "icon-name": "svg:plus",
                        "icon-size": "1.125rem",
                        width: "100px",
                        text: __props.addItemLabel,
                        onClick: __props.createCallback
                      }, null, 8, ["text", "onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["errors", "no-data-text", "no-data-image"])
            ])
          ]))
        : _createCommentVNode("", true)
  ], 2)), [
    [_directive_layout, (value) => (layout.value = value)]
  ])
}
}

})